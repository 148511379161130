<template>
  <div class="comming-others-wrapper">
    <section>
      <BettingOffer />
    </section>
  </div>
</template>

<script>
import BettingOffer from '@/components/Retail/BettingOffer';
import EventBus from '@/store/EventBus';
import { workerPostMessage } from '@/utility/workerMessage';
import { first } from 'lodash';

export default {
  name: 'ComingOtherWrapper',
  components: { BettingOffer },
  methods: {
    async updateOthers() {
      const isVisualizationDrawEnabled = await this.$store.dispatch('fetchUpcomingEvents', 'others');
      this.selectLayout = isVisualizationDrawEnabled ? 'CommingDrawsWrapper' : 'ComingOtherWrapper';
      workerPostMessage('updateEventsTime', {
        drawTime: null,
        otherNextTime: first(this.$store.getters.bettingOffer(0))?.eventStartTimeUTC - 60000,
      });
      return true;
    },
  },
  mounted() {
    workerPostMessage('stopCounter', null);
    workerPostMessage('startOthersInterval', {
      otherNextTime: first(this.$store.getters.bettingOffer(0))?.eventStartTimeUTC - 60000,
    });
    EventBus.$on('fetchOnlyOthers', () => {
      this.updateOthers();
    });
  },
};
</script>

<style lang="scss" scoped>
  .comming-others-wrapper {
    max-height: calc(100% - 50px);
    padding: 16px;
    position: relative;
    top: 50px;
    > section {
      > div {
        padding: 16px;
        background: rgba(45, 45, 45, 0.2);
        backdrop-filter: blur(10.9683px);
        border-radius: 8px;
        box-shadow: 0 2px 8px rgb(0 0 0 / 50%);
        @media (min-width: 1920px) {
          padding: 24px;
        }
      }
    }
    @media (min-width: 1920px) {
      height: calc(100% - 80px);
      max-height: calc(100% - 80px);
      top: 80px;
    }
  }
</style>
